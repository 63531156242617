export const bookPublicationData =[
 {
   SlNo: 1,
   TeacherName: "Prof. Dusmanta Kumar Pattnaik",
   BookTitle: "The Literary Voyage",
   National: "National",
   YearOfPublication: 2015,
   ISBNNumber: "2348-5272",
   PublisherName: "Rachana Publishing House, Karnataka"
},
 {
   SlNo: 2,
   TeacherName: "Dr. Rajesh Kumar Sain",
   BookTitle: "Financial Markets, institutions and services",
   National: "National",
   YearOfPublication: 2018,
   ISBNNumber: "978-93-86714-78-7",
   PublisherName: "Kunal Books, New Delhi"
},
 {
   SlNo: 3,
   TeacherName: "Dr. Rajesh Kumar Sain",
   BookTitle: "Advance Management Accounting",
   National: "National",
   YearOfPublication: 2020,
   ISBNNumber: "978-93-90110-64-3",
   PublisherName: "Sawaranjali PublicationPvt. Ltd., Ghaziabad"
},
 {
   SlNo: 4,
   TeacherName: "Dr. Rajesh Kumar Sain",
   BookTitle: "Financial Statement Analysis",
   National: "National",
   YearOfPublication: 2020,
   ISBNNumber: "978-93-89657-46-3",
   PublisherName: "Bharati Publication New Delhi"
},
 {
   SlNo: 5,
   TeacherName: "Dr. Rajesh Kumar Sain",
   BookTitle: "International Financial Management",
   National: "National",
   YearOfPublication: 2020,
   ISBNNumber: "978-93-89234-32-9",
   PublisherName: "Kunal Books, New Delhi"
},
 {
   SlNo: 6,
   TeacherName: "Dr. Rajesh Kumar Sain",
   BookTitle: "Contemporary Issues in Social Science ",
   National: "National",
   YearOfPublication: 2020,
   ISBNNumber: "978-93-8922-93-1",
   PublisherName: "Kunal Books, New Delhi"
},
 {
   SlNo: 7,
   TeacherName: "Dr. Ratidev Samal",
   BookTitle: "Consumer Behaviour",
   National: "National",
   YearOfPublication: 2021,
   ISBNNumber: "978-93-8911-743-1",
   PublisherName: "City Publiation, New Delhi"
},
 {
   SlNo: 8,
   TeacherName: "Dr. Rajesh Kumar Sain",
   BookTitle: "Cost Accounting",
   National: "National",
   YearOfPublication: 2021,
   ISBNNumber: "978-93-87684-91-1",
   PublisherName: "JSR Publishing House Llp, New Delhi"
},
 {
   SlNo: 9,
   TeacherName: "Dr. Ratidev Samal",
   BookTitle: "Business, Economics and Society ",
   National: "National",
   YearOfPublication: 2021,
   ISBNNumber: "978-93-90937-31-8",
   PublisherName: "Red'Shine Publication Pvt. LTD., Lunawada"
},
 {
   SlNo: 10,
   TeacherName: "Dr. Ratidev Samal",
   BookTitle: "Emerging Issues in Commerce, Economics Information Technology and Management",
   National: "National",
   YearOfPublication: 2021,
   ISBNNumber: "979-8-4997-8852-3",
   PublisherName: "Red'Shine Publication Pvt. LTD., Lunawada"
},
 {
   SlNo: 11,
   TeacherName: "Dr. Rajesh Kumar Sain",
   BookTitle: "Financial Accounting",
   National: "National",
   YearOfPublication: 2022,
   ISBNNumber: "978-93-9168-11-1",
   PublisherName: "Bharati Publication New Delhi"
},
 {
   SlNo: 12,
   TeacherName: "Dr. N. Sugundan",
   BookTitle: "Leadership in Business and Change Management",
   National: "National",
   YearOfPublication: 2022,
   ISBNNumber: "978-93-95468-99-2",
   PublisherName: "AGPH Books"
},
 {
   SlNo: 13,
   TeacherName: "Dr. Rajesh Kumar Sain",
   BookTitle: "Security Analysis and Portfolio Management",
   National: "National",
   YearOfPublication: 2022,
   ISBNNumber: "978-93-94779-89-1",
   PublisherName: "Bharati Publications"
}
];