export const coursesData = [
	{
		title: "MBA",
		link: "/academics/courses/mba",
		description: "Masters in Business Administration",
		imageUrl: require("../assets/courses/card/mba.jpg"),
	},
	{
		title: "MCA",
		link: "/academics/courses/mca",
		description: "Masters in Computer Application",
		imageUrl: require("../assets/courses/card/mca.jpg"),
	},
];
