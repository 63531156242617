import React from "react";
import SportingActivitiesResponsive from "../../components/SportingActivitiesResponsive/SportingActivitiesResponsive";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import SportingActivitiesGallaryResponsive from "../../components/SportingActivitiesGallaryResponsive/SportingActivitiesGallaryResponsive";

const SportingActivitiesPage = () => {
	return (
		<PageWrapper>
			<HeroSection className="bg-page-background-4">
				<HeroCaption
					title="Sporting Activities"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<SportingActivitiesResponsive />
			<SportingActivitiesGallaryResponsive />
		</PageWrapper>
	);
};

export default SportingActivitiesPage;
