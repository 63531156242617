import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
// import EnquiryFormResponsive from "../EnquiryFormResponsive/EnquiryFormResponsive";
import PageSection from "../PageSection/PageSection";
// import ContactUsForm from "../ContactUsFrom/ContactUsForm";
import { BsFillHouseFill } from "react-icons/bs";
import CustomIcon from "../CustomIcon/CustomIcon";

import { BiWorld } from "react-icons/bi";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import { aboutUsCardsData } from "../../static-data/aboutus.data";
import AboutUsMiniCard from "../AboutUsMiniCard/AboutUsMiniCard";
// import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
const AlumniRegisterResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="Alumni Registration" />
			<p className={descriptionClasses}>
				" Join the NIBA alumni network and reconnect with your peers! "
			</p>
			<a
				href="https://forms.gle/fpBAhrP8SPNGCqDQ6"
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<span className="ml-2">Registration Form</span>
			</a>
		</PageSection>
	);
};

export default AlumniRegisterResponsive;
