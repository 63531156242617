import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import CourseInfoResponsive from "../../components/CourseInfoResponsive/CourseInfoResponsive";
import FacultyResponsive from "../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../components/CoursesResponsive/CoursesResponsive";
import JornalPublicationResponsive from "../../components/JornalPublicationResponsive/JornalPublicationResponsive";
import ResearchInfoResponsive from "../../components/ResearchInfoResponsive/ResearchInfoResponsive";
import BookPublicationResponsive from "../../components/BookPublicationResponsive/BookPublicationResponsive";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import ResearchArchives from "../../components/ResearchArchives/ResearchArchives";

const ResearchPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-7">
				<HeroCaption
					title="Research"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<ResearchInfoResponsive />
			<JornalPublicationResponsive />
			<BookPublicationResponsive/>
			
			{/* <FacultyResponsive /> */}
			{/* <CoursesResponsive /> */}

			<a
				href={process.env.PUBLIC_URL + "/MOUs and Collaboration.pdf"}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">MoUs & Collaborations</span>
			</a>

			<ResearchArchives/>

		</PageWrapper>
	);
};

export default ResearchPage;
