export const socialMediaData = [
	{
		avatarUrl: require("../assets/social-blogs/social-avatar.png"),
		iconUrl: require("../assets/social-blogs/instagram.png"),
		bannerUrl: require("../assets/social-blogs/9.png"),
		name: "NIIS Institute of Business Administration",
		username: "@niis_group",
		likes: "275",
		dateTime: "31 October, 2023",
		desciption: "NIIS Institute of Business Administration conducted an insightful workshop on “Role of Youth in development” in collaboration with @fesonline India, Schumacher Centre and ADS. With dynamic conversations and shared perspectives we explored how youth can drive positive change and contribute to our society’s growth.",
	},
	{
		avatarUrl: require("../assets/social-blogs/social-avatar.png"),
		iconUrl: require("../assets/social-blogs/instagram.png"),
		bannerUrl: require("../assets/social-blogs/6.jpg"),
		name: "NIIS Institute of Business Administration",
		username: "@niis_group",
		likes: "288",
		dateTime: "31 October, 2023",
		desciption: "Welcoming the trailblazers of the future 🚀✨! We welcomed the MBA & MCA Batch of 2025 with our orientation program on 16th September. We express our gratitude to our esteemed gusts, Prof. (Dr.) Amiya Kumar Rath, Honorable Vice-Chancellor of BPUT Rourkela; Minati Singha, Senior editor at Times of India Bhubaneswar; Avinash Samal, Head(Learning & Development) TCS, Odisha. We wish all the best to our new students on their journey towards knowledge, growth & limitless possibilities ahead!",
	},
	{
		avatarUrl: require("../assets/social-blogs/social-avatar.png"),
		iconUrl: require("../assets/social-blogs/instagram.png"),
		bannerUrl: require("../assets/social-blogs/7.png"),
		name: "NIIS Institute of Business Administration",
		username: "@niis_group",
		likes: "211",
		dateTime: "16 April, 2023",
		desciption: `In recognition of their extensive contributions to higher education in the state of Odisha, the Hon’ble Governor of Odisha Ganesha Lal honoured our Chairman, Dr. Subhash Chandra Nayak, and our managing trustee, Prof. Smrutisudha Nayak, at the Urs ceremony held in Khordha.`,
	},
	{
		avatarUrl: require("../assets/social-blogs/social-avatar.png"),
		iconUrl: require("../assets/social-blogs/instagram.png"),
		bannerUrl: require("../assets/social-blogs/5.jpeg"),
		name: "NIIS Institute of Business Administration",
		username: "@niis_group",
		likes: "102",
		dateTime: "17 April, 2022",
		desciption: `Dr.Subhash Chandra Nayak, founder cum Secretary of NIIS group of Institutions and Mrs.Smrutisudha Nayak, CEO of NIIS group of Institutions have been felicitated by his Excellency ,honourable Governor of Andhra Pradesh Biswabhusan Harichandan.`,
	},
	{
		avatarUrl: require("../assets/social-blogs/social-avatar.png"),
		iconUrl: require("../assets/social-blogs/instagram.png"),
		bannerUrl: require("../assets/social-blogs/4.jpg"),
		name: "NIIS Institute of Business Administration",
		username: "@niis_group",
		likes: "95",
		dateTime: "20 April, 2022",
		desciption: `NIIS Institute of Business Administration, a constituent college of NIIS Group of Institutions conducted a National Seminar: "Revisiting the landscape of IoT: A technology for common man"`,
	},
	{
		avatarUrl: require("../assets/social-blogs/social-avatar.png"),
		iconUrl: require("../assets/social-blogs/instagram.png"),
		bannerUrl: require("../assets/social-blogs/1.jpeg"),
		name: "NIIS Institute of Business Administration",
		username: "@niis_group",
		likes: "125",
		dateTime: "26 April, 2022",
		desciption:
			"Our beloved Founder Chairman was awarded the Business Eminence Award for 2022 by Tathagata Satpathy, Former Member of Parliament & editor of renowned English daily Orissa POST & Odiya daily, Dharitri & Naveen Jindal, Chairman of Jindal Steel & Power Limited & Chancellor of O.P Jindal Global University.",
	},
	{
		avatarUrl: require("../assets/social-blogs/social-avatar.png"),
		iconUrl: require("../assets/social-blogs/instagram.png"),
		bannerUrl: require("../assets/social-blogs/2.jpg"),
		name: "NIIS Institute of Business Administration",
		username: "@niis_group",
		likes: "73",
		dateTime: "21 May, 2022",
		desciption: `NIIS Institute of Business Administration, a constituent college of NIIS Group of Institutions conducted a National Seminar: "Industry 4.0: The Evolving Business Landscape."`,
	},
	{
		avatarUrl: require("../assets/social-blogs/social-avatar.png"),
		iconUrl: require("../assets/social-blogs/instagram.png"),
		bannerUrl: require("../assets/social-blogs/3.jpg"),
		name: "NIIS Institute of Business Administration",
		username: "@niis_group",
		likes: "73",
		dateTime: "30 December, 2021",
		desciption:
			"With the divine blessings of Shri Sai Baba,the inauguration of new temple on NIIS premises took place on 1st December 2021.",
	},
	

	// add more entry here
];
