import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const GuidelineResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="Guidelines" />
			<p className={descriptionClasses}>
				Every institute existing in our culture should have guidelines for its
				smooth functioning and for the proper maintenance of decorum. Hence we
				had set some basic rules for all the students on campus which are always
				quintessential for their safety and well being. Any discrepancies
				committed by students are taken into the hands of the concerned
				discipline administration and are dealt strictly.
			</p>
			<a
				href={process.env.PUBLIC_URL + "/Students Manual (1).pdf"}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">DOWNLOAD STUDENTS MANUAL</span>
			</a>
		</PageSection>
	);
};

export default GuidelineResponsive;
