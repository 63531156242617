import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const LibraryInfoResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="NIBA Campus" />
			<p className={descriptionClasses}>
				The sophisticated infrastructure and amenities around the campus are
				made with care and lots of planning so as to provide a safe environment
				to students where they can thrive and prosper with good education,
				eating habits and good health. <br /> The sprawling 10 acres campus with
				over 2.5 Lakh sqft. built up area has various sections of academic
				wings, hostel facilities, lab and workshops, playing field and leisure
				grounds help children to sustain in a free and spacious environment
				without any compromises on accommodation and study.
			</p>
		</PageSection>
	);
};

export default LibraryInfoResponsive;
