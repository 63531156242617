import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import IndustryInfographyResponsive from "../../components/IndustryInfographyResponsive/IndustryInfographyResponsive";
import AboutAlumniResponsive from "../../components/AboutAlumniResponsive/AboutAlumniResponsive";
import AlumniRecordResponsive from "../../components/AlumniRecordResponsive/AlumniRecordResponsive";
import AlumniAssoRecordResponsive from "../../components/AlumniAssoRecordResponsive/AlumniAssoRecordResponsive";
import AlumniStoriesResponsive from "../../components/AlumniStoriesResponsive/AlumniStoriesResponsive";
import AlumniRegisterResponsive from "../../components/AlumniRegisterResponsive/AlumniRegisterResponsive";

const AlumniPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-4">
				<HeroCaption
					title="Alumni"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>

			<AboutAlumniResponsive />
			<IndustryInfographyResponsive />
			<AlumniRecordResponsive />
			<AlumniAssoRecordResponsive />
			<AlumniStoriesResponsive />
			<AlumniRegisterResponsive />
		</PageWrapper>
	);
};

export default AlumniPage;

// <AboutNibaResponsive />
// <TrusteeResponsive />
// <InfographyResponsive />
// <FromStudentsResponsive />
// <QualityPolicyContentResponsive />
