import React from "react";
import { Image } from "react-bootstrap";

import "./studentFeedbackCard.style.scss";

const AlumniCard = ({ name, companyName, imageUrl }) => {
	return (
		<div className="student-feedback-card mb-4">
			<div
				className="student-feedback-card--head"
				style={{ overflow: " hidden", height: "220px" }}
			>
				<Image
					src={imageUrl.default ? imageUrl.default : imageUrl}
					alt={name}
					className="img-fluid student-feedback-card--image"
				/>
			</div>
			<div className="student-feedback-card--title ">
				<h6 className="display-9 font-weight-bold text-heading-color mb-0 ">
					{name}
				</h6>
				<p className="text-highlight-color mb-0" style={{ fontSize: "12px" }}>
					{companyName}
				</p>
			</div>
			{/* <div className="student-feedback-card--content">
				<p className="font-md font-italic"></p>
			</div> */}
		</div>
	);
};

export default AlumniCard;
