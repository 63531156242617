export const sem1 = [
	{
		id: 1,
		title: "C AND DATASTRUCTURE",
		link: "/lessonplanmca/sem1/C AND DATASTRUCTURE.docx",
	},
	{
		id: 2,
		title: "COMPUTER SYSTEM ARCHITECHTURES",
		link: "/lessonplanmca/sem1/COMPUTER SYSTEM ARCHITECHTURES.docx",
	},
	{
		id: 3,
		title: "DATABASE ENGINEERING",
		link: "/lessonplanmca/sem1/DATABASE ENGINEERING.docx",
	},
	{
		id: 4,
		title: "DISCRETE MATHEMATICS",
		link: "/lessonplanmca/sem1/DISCRETE MATHEMATICS.docx",
	},
	{
		id: 5,
		title: "OPERATING SYSRTEM",
		link: "/lessonplanmca/sem1/OPERATING SYSRTEM.docx",
	},
];

export const sem2 = [
	{
		id: 1,
		title: "ANALYSIS AND DESIGN OF ALGORITHM",
		link: "/lessonplanmca/sem2/ANALYSIS AND DESIGN OF ALGORITHM.docx",
	},
	{
		id: 2,
		title: "INTERNET AND WEB PROGRAMING",
		link: "/lessonplanmca/sem2/INTERNET AND WEB PROGRAMING.docx",
	},
	{
		id: 3,
		title: "JAVA",
		link: "/lessonplanmca/sem2/JAVA.docx",
	},
	{
		id: 4,
		title: "UML",
		link: "/lessonplanmca/sem1/UML.docx",
	},
];

export const sem3 = [
	{
		id: 1,
		title: "CD 2021 ADMN BATCH",
		link: "/lessonplanmca/sem3/CD 2021 ADMN BATCH.docx",
	},
	{
		id: 2,
		title: "SE 2021 ADMN BATCH",
		link: "/lessonplanmca/sem3/SE 2021 ADMN BATCH.docx",
	},
];

