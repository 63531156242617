import React from "react";
import { Image } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { publicationData } from "../../static-data/publication.data";
import { publicationData2 } from "../../static-data/publication.data";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
const JournalPublicationResponsive = () => {
	return (
		<>
			<PageSection bgClass="bg-10">
				<ResponsivePageHeader centered={true} title="Journal  Publications" />

				<Table striped bordered>
					<thead>
						<tr>
							{/* <th>Sl. No.</th> */}
							{/* <th>Title of Paper</th>
							<th>Name of the author/s</th>

							<th>National/ International</th>
							<th>Year of publication</th>
							<th>ISBN number of the proceeding</th>
							<th>Affiliating Institute at the time of publication </th>
							<th>Name of the publisher</th> */}

							<th>Title of Paper</th>
							<th>Name of the author/s</th>

							<th>Department of the teacher</th>
							<th>Name of journal</th>
							<th>Year of publication</th>
							<th>ISSN number</th>
							<th>Link to website of the Journal</th>
							{/* <th>Link to article / paper / abstract of the article</th> */}
							<th>Is it listed in UGC Care list</th>
						</tr>
					</thead>
					<tbody>
						{publicationData.map((val) => {
							console.log(val.length);
							return (
								<tr>
									<td>{val.Title}</td>
									<td>{val.Author}</td>

									<td>{val.Department}</td>
									<td>{val.Journal}</td>
									<td>{val.Year}</td>
									<td>{val.ISSN}</td>
									<td style={{ width: "10px" }}>
										<a
											style={{
												all: "unset",
												textDecoration: "underline",
												cursor: "pointer",
											}}
											href={val.JournalLink}
											target="_blank"
											rel="noreferrer"
										>
											Link
										</a>
									</td>
									<td>{val.UGCListed}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</PageSection>
			
			
		</>
	);
};

export default JournalPublicationResponsive;
