import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
// import EnquiryFormResponsive from "../EnquiryFormResponsive/EnquiryFormResponsive";
import PageSection from "../PageSection/PageSection";
// import ContactUsForm from "../ContactUsFrom/ContactUsForm";
import { BsFillHouseFill } from "react-icons/bs";
import CustomIcon from "../CustomIcon/CustomIcon";

import { BiWorld } from "react-icons/bi";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import { aboutUsCardsData } from "../../static-data/aboutus.data";
import AboutUsMiniCard from "../AboutUsMiniCard/AboutUsMiniCard";
import AlumniCard from "../AlumniCard/AlumniCard";
// import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
const AlumniStoriesResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-white">
			{/* <ResponsivePageHeader
				centered={true}
				title="NIBA Alumni Across the World! 
 "
			/> */}
			<Row className="justify-content-between">
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Alumni Stories
						</p>
						<p style={{ textAlign: "justify" }}>
							We feature inspiring stories of our alumni, highlighting their
							achievements, career paths, and personal journeys. We want to
							inspire the next generation of NIBA graduates with the stories of
							our successful alumni.
						</p>
					</div>
				</Col>
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Events
						</p>
						<p style={{ textAlign: "justify" }}>
							We organize alumni events, both online and offline, to enable our
							alumni to connect with each other, share experiences, and network.
							We believe that these events are an excellent opportunity for our
							alumni to reconnect with old friends and make new ones.
						</p>
					</div>
				</Col>
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Job Opportunities
						</p>
						<p style={{ textAlign: "justify" }}>
							We share job opportunities with our alumni, helping them to
							advance in their careers. We are always on the lookout for
							opportunities that can benefit our alumni.
						</p>
					</div>
				</Col>
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Giving Back
						</p>
						<p style={{ textAlign: "justify" }}>
							We provide opportunities for our alumni to give back to the
							institution that helped shape their careers. Alumni can volunteer
							as mentors, guest speakers, or donate to support the development
							of the institution.
						</p>
					</div>
				</Col>
			</Row>
		</PageSection>
	);
};

export default AlumniStoriesResponsive;
