import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import StudentFeedbackCard from "../StudentFeedbackCard/StudentFeedbackCard";
import { studentsFeedbackData } from "../../static-data/studentsfeedback.data";

const FromStudentsResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const swiperContainerClasses = classNames("position-relative", {
		"mb-4": currentBreakPoint === "small" || currentBreakPoint === "xSmall",
	});

	const paginationClasses = classNames(
		"student-feedback-pagination swiper-pagination-container swiper-pagination-container--horizontal swiper-pagination-container--horizontal---right",
		{
			"swiper-pagination-container--top": !(
				currentBreakPoint === "small" || currentBreakPoint === "xSmall"
			),
		},
		{
			"swiper-pagination-container--bottom":
				currentBreakPoint === "small" || currentBreakPoint === "xSmall",
		}
	);

	return (
		<PageSection bgClass="page-background-1">
			<ResponsivePageHeader title="From Our Students" />

			<div className={swiperContainerClasses}>
				<Swiper
					grabCursor={true}
					direction="horizontal"
					scrollbar={{ el: "", draggable: true, hide: true }}
					slidesPerView={
						currentBreakPoint === "small" || currentBreakPoint === "xSmall"
							? 1
							: 3
					}
					spaceBetween={20}
					loop={true}
					autoplay={{ delay: 3000 }}
					pagination={{
						el: ".student-feedback-pagination",
						clickable: true,
					}}
				>
					{studentsFeedbackData.map((data, index) => (
						<SwiperSlide key={`student-events-home${index}`}>
							<StudentFeedbackCard {...data} />
						</SwiperSlide>
					))}
				</Swiper>
				<div className={paginationClasses}></div>
			</div>
		</PageSection>
	);
};

export default FromStudentsResponsive;
