import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const SportingActivitiesResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="Sporting Activities" />
			<p className={descriptionClasses}>
                Here at NIIS,  we understand the importance of sports in the overall development of our students. 
                Our state-of-the-art sports facilities and dedicated coaching staff ensure that our students receive 
                the best possible training and opportunities to excel in their chosen sport. 
                Whether they are aspiring athletes or simply looking to stay fit, we have something for everyone.
                In addition to regular training sessions, we organize friendly matches, inter-house competitions, 
                and inter-school tournaments, providing students with opportunities to showcase their abilities and compete at various levels.
                These events foster a spirit of healthy competition and camaraderie among our students.

			</p>
		</PageSection>
	);
};

export default SportingActivitiesResponsive;
