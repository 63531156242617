import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const QualityPolicyContentResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames(
		"text-white mb-4 text-center text-text-color",
		{
			"font-sm":
				currentBreakPoint === "xSmall" || currentBreakPoint === "small",
		}
	);

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<PageSection>
			<ResponsivePageHeader centered={false} title="Quality Policy" />
			<div className="ml-2">
				<ul>
					<li>
						<p>
							Providing excellent infrastructure for an abiotic academic
							ambience
						</p>
					</li>
					<li>
						<p>
							Ensuring value-based education through an excellent teaching
							learning process.
						</p>
					</li>
					<li>
						<p>
							Developing competence of students through experiential learning
							and project work.
						</p>
					</li>
					<li>
						<p>
							Providing adequate exposure to contemporary business scenarios,
							innovation and entrepreneurship through corporate exposure and
							alumni mentoring.
						</p>
					</li>
					<li>
						<p>
							Enhancing technical behavioral skills of students through
							professional training and practice.
						</p>
					</li>
					<li>
						<p>
							Exploring student talent in curricular and co-curricular
							activities.
						</p>
					</li>
					<li>
						<p>
							Incubating professional values and ethics in young minds as life
							skill.
						</p>
					</li>
					<li>
						<p>
							Orienting students as environmentally sensitive and socially
							responsible.
						</p>
					</li>
				</ul>
			</div>
		</PageSection>
	);
};

export default QualityPolicyContentResponsive;
