export const alumniData = [
	{
		name: "Manas Malik",
		imageUrl: require("../assets/alumni/2020 (3).jpg"),
		companyName: " SBI Capital",
		profile: "",
		year: "2020",
	},
	{
		name: "Rabi Narayan Khuntia",
		imageUrl: require("../assets/alumni/2017 (2).jpg"),
		companyName: "Axis Bank",
		profile: "Deputy Manager",
		year: "2018",
	},
	{
		name: "Kiranjeet Behera ",
		imageUrl: require("../assets/alumni/2017 (5).jpg"),
		companyName: "Airtel",
		profile: "Deputy Manager Operations",
		year: "2017",
	},
	{
		name: "Sunil Kumar Patra",
		imageUrl: require("../assets/alumni/2018 (1).jpg"),
		companyName: " ICICI Bank",
		profile: "",
		year: "2018",
	},
	{
		name: "Sarati Dash",
		imageUrl: require("../assets/alumni/2022 (3).jpg"),
		companyName: "Kotak Mahindra Bank",
		profile: "",
		year: "2022",
	},
	{
		name: "Madhusmita Rout",
		imageUrl: require("../assets/alumni/2023 (1).jpg"),
		companyName: "PNB Metlife",
		profile: "",
		year: "2023",
	},
	{
		name: "Rupesh Kumar",
		imageUrl: require("../assets/alumni/2020 (1).jpg"),
		companyName: "PNB",
		profile: "",
		year: "2020",
	},
	// {
	// 	name: "Suryakanta Nayak",
	// 	imageUrl: require("../assets/alumni/2020 (2).jpg"),
	// 	companyName: " Black Knight US",
	// 	profile: "",
	// 	year: "2020",
	// },
	{
		name: "S. Sunidhi",
		imageUrl: require("../assets/alumni/2020 (5).jpg"),
		companyName: "BYJU's",
		profile: "",
		year: "2020",
	},
	{
		name: "Gopabandhu Das",
		imageUrl: require("../assets/alumni/2018 (3).jpg"),
		companyName: "Deloitte",
		profile: "",
		year: "2018",
	},
	{
		name: "Narasingha Das",
		imageUrl: require("../assets/alumni/2017 (1).jpg"),
		companyName: "Capgemini",
		profile: "Associate Consultant",
		year: "2017",
	},

	{
		name: "Prashanti Kolla",
		imageUrl: require("../assets/alumni/2017 (3).jpg"),
		companyName: " UPS",
		profile: "Financial Analyst",
		year: "2017",
	},
	{
		name: "Priyanka Prusti",
		imageUrl: require("../assets/alumni/2018 (2).jpg"),
		companyName: " Tech Mahindra",
		profile: "",
		year: "2018",
	},
	{
		name: "Swagatika Sahoo Karvy ",
		imageUrl: require("../assets/alumni/2017 (4).jpg"),
		companyName: "Stock Broking",
		profile: "Deputy Manager",
		year: "2017",
	},
	{
		name: "Siva Kumari Balasa",
		imageUrl: require("../assets/alumni/2018 (5).jpg"),
		companyName: " UPS",
		profile: "",
		year: "2018",
	},
	{
		name: "Ashutosh Majhi",
		imageUrl: require("../assets/alumni/2018 (6).jpg"),
		companyName: "UPS",
		profile: "",
		year: "2018",
	},

	{
		name: "Amar Nath",
		imageUrl: require("../assets/alumni/2018 (4).jpg"),
		companyName: "Genpact & Wipro",
		profile: "",
		year: "2018",
	},

	{
		name: "Sneha Gupta",
		imageUrl: require("../assets/alumni/2019 (1).jpg"),
		companyName: " SP Global",
		profile: "",
		year: "2019",
	},

	{
		name: "Gyanalipsa Behera",
		imageUrl: require("../assets/alumni/2020 (4).jpg"),
		companyName: " Gallagher",
		profile: "",
		year: "2020",
	},

	{
		name: "Sangita Mohapatra",
		imageUrl: require("../assets/alumni/2022 (1).jpg"),
		companyName: "Agarwal packers & movers",
		profile: "",
		year: "2022",
	},
	{
		name: "Shiwam Singh",
		imageUrl: require("../assets/alumni/2022 (2).jpg"),
		companyName: "Merck India",
		profile: "",
		year: "2022",
	},
];

export const alumniAssoData = [
	{
		name: "Debaranjan Mishra",
		imageUrl: require("../assets/alumniasso/Debaranjan Mishra, President, NIBSA SAlumni Association.jpg"),
		companyName: "President, NIBA Alumni Association.jpg",
		profile: "",
		year: "2020",
	},
	{
		name: "Satyajit Parthasarthi Nayak",
		imageUrl: require("../assets/alumniasso/Satyajit Parthasarthi Nsyak - Vice President, NIBA Alumni Association.jpg"),
		companyName: "Vice President, NIBA Alumni Association.jpg",
		profile: "",
		year: "2020",
	},
	{
		name: "Chinmaya Kumar Rout ",
		imageUrl: require("../assets/alumniasso/Chinmaya Kumar Rout - Secretary, NIBA Alumni Assocation.jpg"),
		companyName: "Secretary, NIBA Alumni Assocation",
		profile: "",
		year: "2020",
	},
	{
		name: "Lipika Agasti ",
		imageUrl: require("../assets/alumniasso/Lipika Agasti - Treasurer - NIBA Alumni Assocation.jpg"),
		companyName: "Treasurer - NIBA Alumni Assocation.jpg",
		profile: "",
		year: "2020",
	},
	{
		name: "Aditya Kumar Pattnaik",
		imageUrl: require("../assets/alumniasso/Aditya Kumar Pattnaik, Member, NIBA Alumni Association.jpg"),
		companyName: "Member - NIBA Alumni Assocation.jpg",
		profile: "",
		year: "2020",
	},
	{
		name: "Bichitrananada Biswal",
		imageUrl: require("../assets/alumniasso/Bichitrananada Biswal, Member, NIBA Alumni Association.jpg"),
		companyName: "Member - NIBA Alumni Assocation.jpg",
		profile: "",
		year: "2020",
	},
	{
		name: "Chandan Kar",
		imageUrl: require("../assets/alumniasso/Chandan Kar, Member, NIB Alumni Association.jpg"),
		companyName: "Member - NIBA Alumni Assocation.jpg",
		profile: "",
		year: "2020",
	},
	{
		name: "Jyotirmaya Choudhury",
		imageUrl: require("../assets/alumniasso/Jyotirmaya Choudhury, Member, NIBA Alumni Association.jpg"),
		companyName: "Member - NIBA Alumni Assocation.jpg",
		profile: "",
		year: "2020",
	},
	{
		name: "Sushree Mahapatra",
		imageUrl: require("../assets/alumniasso/Sushree Mahapatra, Member, NIBA Alumni Association.jpg"),
		companyName: "Member - NIBA Alumni Assocation.jpg",
		profile: "",
		year: "2020",
	},
];
