export const publicationData = [
	{
		Title: "Factors Affecting Employee Job Satisfaction in Public Sector",
		Author: "Prof. Gyana Ranjan Biswal",
		Department: "MBA",
		Journal: "Asia Pacific Journal of Research",
		Year: "2017-18",
		ISSN: "2230-5504",
		JournalLink:
			"https://www.researchgate.net/publication/344879305_FACTORS_AFFECTING_EMPLOYEE_JOB_SATISFACTION_IN_PUBLIC_SECTOR",
		ArticleLink: "S.N. 45797 of UGC",
		UGCListed: "Yes",
	},
	{
		Title:
			"An  Analysis of Public Expenditure on Defence Sector : An Indian Experience",
		Author: "Dr. Ajit Narayan Mohanty",
		Department: "MBA",
		Journal:
			"International Journal of Enginering Sciences Paradigms and Researches",
		Year: "2017-18",
		ISSN: "2319-6564",
		JournalLink: "http://www.ijesonline.com/Published_S1117/1.pdf",
		ArticleLink: "Volume 46",
		UGCListed: "Yes",
	},
	{
		Title:
			"Central Bank's Directives, Rana Kapoor's Departure, Yes Bank's Doldrums: A Visionary Approach",
		Author: "Dr. Janmejaya Das",
		Department: "MBA",
		Journal:
			"International Journal of Enginering Sciences Paradigms and Researches",
		Year: "2017-18",
		ISSN: "2319-6564",
		JournalLink: "http://www.ijesonline.com/Published_S1117/2.pdf",
		ArticleLink: "Volume 46",
		UGCListed: "Yes",
	},
	{
		Title:
			"Dynamically coupling of Artificial Neural Network in Honeypot (Network Securitty) Application",
		Author: "Prof. Pravakar Mishra",
		Department: "MCA",
		Journal:
			"International Journal of Enginering Sciences Paradigms and Researches",
		Year: "2017-18",
		ISSN: "2319-6564",
		JournalLink: "http://www.ijesonline.com/Published_S1117/3.pdf",
		ArticleLink: "Volume 46",
		UGCListed: "Yes",
	},
	{
		Title:
			"Effective Implementation of Smart Ideas for Smart City: A Study of Recommendation System in the Decision Making of Smart City Authority in Special Reference to Bhubaneswar City",
		Author: "Prof Bhabani Sankar Rath",
		Department: "MBA",
		Journal:
			"International Journal of Enginering Sciences Paradigms and Researches",
		Year: "2017-18",
		ISSN: "2319-6564",
		JournalLink: "http://www.ijesonline.com/Published_S1117/4.pdf",
		ArticleLink: "Volume 46",
		UGCListed: "Yes",
	},
	{
		Title:
			"Shuffling Between the courts of Metaphor and Metonym: Seamus Heaney's Techniques as a poet and as a Critic",
		Author: "Prof. Dusmanta Kumar Pattanaik",
		Department: "MBA",
		Journal: "LangLit An International Peer Reviewed Open Access Journal",
		Year: "2018-19",
		ISSN: "2349-5189",
		JournalLink: "www.langlit.org",
		ArticleLink:
			"ICI. Google Scholar, Research Gate, Academia.edu, IBI, IIFC, DRJI",
		UGCListed: "Yes",
	},
	{
		Title: "A Data Anlytics Framework for Healthcare Applications",
		Author: "Prof. Kshitish Kumar Mohapatra",
		Department: "MBA",
		Journal:
			"Internatinal Journal of Engineering, Management, Humanities and Social Sciences Paradigms",
		Year: "2018-19",
		ISSN: "2347-601X",
		JournalLink: "http://www.ijemhs.com/Published_S0818/1.pdf",
		ArticleLink: "Volume 30",
		UGCListed: "Yes",
	},
	{
		Title:
			"Fuzzy Analysis in the Study of  Quality Impact of Educational Institutuins for Higher Studies",
		Author: "Prof. Bhabani Sankar Rath",
		Department: "MBA",
		Journal:
			"Internatinal Journal of Engineering, Management, Humanities and Social Sciences Paradigms",
		Year: "2018-19",
		ISSN: "2347-601X",
		JournalLink: "http://www.ijemhs.com/Published_S0818/2.pdf",
		ArticleLink: "Volume 30",
		UGCListed: "Yes",
	},
	{
		Title:
			"Impact of Emotional Intelligence and Stress on Working Mavens: A Study on its Professionals",
		Author: "Dr. Megha Sharma",
		Department: "MBA",
		Journal:
			"Internatinal Journal of Engineering, Management, Humanities and Social Sciences Paradigms",
		Year: "2018-19",
		ISSN: "2347-601X",
		JournalLink: "http://www.ijemhs.com/Published_S0818/3.pdf",
		ArticleLink: "Volume 30",
		UGCListed: "Yes",
	},
	{
		Title: "Young Entrepreneurs: Futureof Indian Economy",
		Author: "Prof. Gyana  Ranjan Biswal",
		Department: "MBA",
		Journal:
			"Internatinal Journal of Engineering, Management, Humanities and Social Sciences Paradigms",
		Year: "2018-19",
		ISSN: "2347-601X",
		JournalLink: "http://www.ijemhs.com/Published_S0818/4.pdf",
		ArticleLink: "Volume 30",
		UGCListed: "Yes",
	},
	{
		Title:
			"Implementation of Linear Discriminant Analysis for Odia Numeral Recognition",
		Author: "Prof. Sradhanjali Nayak",
		Department: "MCA",
		Journal: "IEEE Xplore",
		Year: "2018-19",
		ISSN: "1977347422349-5189",
		JournalLink:
			"https://www.researchgate.net/publication/333497243_Implementation_of_Linear_Discriminant_Analysis_for_Odia_Numeral_Recognition",
		ArticleLink: "DOI:10.1109/ CIT.2018.00043",
		UGCListed: "Yes",
	},
	{
		Title:
			"A Comparative Study of Computerizations Influence for Public & Private Sector Banks",
		Author: "Dr. Megha Sharma",
		Department: "MBA",
		Journal: "Juni Khayat",
		Year: "2019-20",
		ISSN: "2278-4632",
		JournalLink: "http://junikhyatjournal.in/oct_19/156.pdf",
		ArticleLink: "Volume -9, Issue - 10",
		UGCListed: "Yes",
	},
	{
		Title:
			"An Analysis on the Commercial Banks on the Upliftment of DEPRIVED Sections of the Society: A Special Referemce to Puri, Keonjhar and Dhenkanal Districts of Odisha",
		Author: "Dr. Rajesh Sain",
		Department: "MBA",
		Journal: "Juni Khayat",
		Year: "2019-20",
		ISSN: "2278-4632",
		JournalLink: "http://junikhyatjournal.in/oct_19/157.pdf",
		ArticleLink: "Volume -9, Issue - 10",
		UGCListed: "Yes",
	},
	{
		Title: "Demonetisation: A Boon or Bane",
		Author: "Dr. Pramod Kumar Prusty",
		Department: "MBA",
		Journal: "Juni Khayat",
		Year: "2019-20",
		ISSN: "2278-4632",
		JournalLink: "http://junikhyatjournal.in/oct_19/158.pdf",
		ArticleLink: "Volume -9, Issue - 10",
		UGCListed: "Yes",
	},
	{
		Title:
			"Management Education Landscape in India: Trends, Issues and Challenges",
		Author: "Dr. Pramod Kumar Prusty",
		Department: "MBA",
		Journal: "Juni Khayat",
		Year: "2019-20",
		ISSN: "2278-4632",
		JournalLink: "http://junikhyatjournal.in/oct_19/159.pdf",
		ArticleLink: "Volume -9, Issue - 10",
		UGCListed: "Yes",
	},
	{
		Title:
			"Use of Optimized Soft Set Techniques in the Selction Process of Candidates by the Recruiters",
		Author: "Prof. Bhabani Sankar Rath",
		Department: "MBA",
		Journal: "Juni Khayat",
		Year: "2019-20",
		ISSN: "2278-4632",
		JournalLink: "http://junikhyatjournal.in/oct_19/160.pdf",
		ArticleLink: "Volume -9, Issue - 10",
		UGCListed: "Yes",
	},
	{
		Title:
			"Recognition of printed Odia Characters and Digits Using optimized self - organizing Map Network",
		Author: "Prof. Sradhanjali Nayak",
		Department: "MCA",
		Journal:
			"Recognition of printed Odia Characters and Digits Using optimized self - organizing Map Network",
		Year: "2020-21",
		ISBN: "978-1-6654-3361-7/21",
		JournalLink: "ieeexplore.ieee.org",
		ArticleLink: "DOI: 10.1109/ICCSEA49143.2020.9132915",
		UGCListed: "Yes",
	},
	{
		Title: "5G Technology Advancement in Management",
		Author: "Prof. Satyajit Pattanaik",
		Department: "MBA",
		Journal: "Dogo Rangsang Research Journal",
		Year: "2020-21",
		ISSN: "2347-7180",
		JournalLink: "https://journal-dogorangsang.in/no_1_Online_20/44_aug.pdf",
		ArticleLink: "Volume - 10, Issue 08",
		UGCListed: "Yes",
	},
	{
		Title:
			"Cashless Transaction Systems: A Study of Paradigm Shift in Indian Conusmer Behaviour",
		Author: "Dr. Pramod Kumar Prusty",
		Department: "MBA",
		Journal: "Dogo Rangsang Research Journal",
		Year: "2020-21",
		ISSN: "2347-7180",
		JournalLink: "https://journal-dogorangsang.in/no_1_Online_20/45_aug.pdf",
		ArticleLink: "Volume - 10, Issue 08",
		UGCListed: "Yes",
	},
	{
		Title:
			"Yes Bank Crisis - A Critical Analysis on Causes, Effects & Recommendations",
		Author: "Prof. Tushar Ranjan Barik",
		Department: "MBA",
		Journal: "Dogo Rangsang Research Journal",
		Year: "2020-21",
		ISSN: "2347-7180",
		JournalLink: "https://journal-dogorangsang.in/no_1_Online_20/46_aug.pdf",
		ArticleLink: "Volume - 10, Issue 08",
		UGCListed: "Yes",
	},
	{
		Title:
			"Impact of Dataminin Approaches in the Constructive Development of Organisation Sustainability",
		Author: "Prof. Bhabani Sankar Rath",
		Department: "MBA",
		Journal: "Dogo Rangsang Research Journal",
		Year: "2020-21",
		ISSN: "2347-7180",
		JournalLink: "https://journal-dogorangsang.in/no_1_Online_20/47_aug.pdf",
		ArticleLink: "Volume - 10, Issue 08",
		UGCListed: "Yes",
	},
	{
		Title:
			"Machine Learning Prospects in Supply Chain Network Design(scnd): A Case Study",
		Author: "Prof. Bhabani Sankar Rath",
		Department: "MBA",
		Journal: "Dogo Rangsang Research Journal",
		Year: "2020-21",
		ISSN: "2347-7180",
		JournalLink: "https://journal-dogorangsang.in/no_1_Online_20/48_aug.pdf",
		ArticleLink: "Volume - 10, Issue 08",
		UGCListed: "Yes",
	},
	{
		Title:
			"Impact of Covid-19 on Broader indices of Indian Stock Market: A Behavioural Finance Approach",
		Author: "Dr. Megha Sharma",
		Department: "MBA",
		Journal: "Dogo Rangsang Research Journal",
		Year: "2020-21",
		ISSN: "2347-7180",
		JournalLink: "https://journal-dogorangsang.in/no_1_Online_20/49_aug.pdf",
		ArticleLink: "Volume - 10, Issue 08",
		UGCListed: "Yes",
	},
	{
		Title:
			"Politics of Intention and Poetics of Rhetoricity: A combinatory Exercise in the Select Works of V.S. Naipaul",
		Author: "Dr. Dusmanta Kumar Pattanaik",
		Department: "MBA",
		Journal: "LangLit An International Peer Reviewed Open Access Journal",
		Year: "2021-22",
		ISSN: "2349-5189",
		JournalLink: "www.langlit.org",
		ArticleLink: "volume-5, Issue-1",
		UGCListed: "Yes",
	},
	{
		Title:
			"A Theoretical Review in Publicity and Preservation Technology on Multiple Supply Chain Network Design (MSCND) WITH Heuristic Approach",
		Author: "Prof. Bhabani Sankar Rath",
		Department: "MBA",
		Journal: "Industrial Engineering Journal",
		Year: "2021-22",
		ISSN: "0970-2555",
		JournalLink: "http://www.journal-iiie-india.com/1_jan-dec_22/99_may.pdf",
		ArticleLink: "Volume 51, Issue 5",
		UGCListed: "Yes",
	},
	{
		Title:
			"Contribution of International Organization in Eritrea: A case study of Red Cross",
		Author: "Dr. Bimal Kanta Nayak",
		Department: "MBA",
		Journal: "Industrial Engineering Journal",
		Year: "2021-22",
		ISSN: "0970-2555",
		JournalLink: "http://www.journal-iiie-india.com/1_jan-dec_22/100_may.pdf",
		ArticleLink: "Volume 51, Issue 5",
		UGCListed: "Yes",
	},
	{
		Title: "Requisite Implementing Conditions for E-Payment System",
		Author: "Prof. Sumita Dhar",
		Department: "MCA",
		Journal: "Industrial Engineering Journal",
		Year: "2021-22",
		ISSN: "0970-2555",
		JournalLink: "http://www.journal-iiie-india.com/1_jan-dec_22/101_may.pdf",
		ArticleLink: "Volume 51, Issue 5",
		UGCListed: "Yes",
	},
	{
		Title:
			"Merger and Acquisitions of Indian Banks: A Meaningful Force in the Organization of Financial Sector",
		Author: "Prof. Uttam Giri",
		Department: "MBA",
		Journal: "Industrial Engineering Journal",
		Year: "2021-22",
		ISSN: "0970-2555",
		JournalLink: "http://www.journal-iiie-india.com/1_jan-dec_22/103_may.pdf",
		ArticleLink: "Volume 51, Issue 5",
		UGCListed: "Yes",
	},
	{
		Title:
			"Self Help Groups: A Sustainable Model for Empowering Rural Odisha: A Case Analysis",
		Author: "Dr. Rajesh Sain",
		Department: "MBA",
		Journal: "Industrial Engineering Journal",
		Year: "2021-22",
		ISSN: "0970-2555",
		JournalLink: "http://www.journal-iiie-india.com/1_jan-dec_22/104_may.pdf",
		ArticleLink: "Volume 51, Issue 5",
		UGCListed: "Yes",
	},
	{
		Title:
			"Servqual Attributes, Customer Satisfaction and Loyalty: A Relationship Study of Public Sector Banks in Odisha",
		Author: "Dr. Ratidev Samal",
		Department: "MBA",
		Journal: "Industrial Engineering Journal",
		Year: "2021-22",
		ISSN: "0970-2555",
		JournalLink: "http://www.journal-iiie-india.com/1_jan-dec_22/105_may.pdf",
		ArticleLink: "Volume 51, Issue 5",
		UGCListed: "Yes",
	},
	{
		Title:
			"Green Field Investment and Their All-Round Progress in Odisha with Special Reference to Irrigation Sector",
		Author: "Dr. Rajesh Sain",
		Department: "MBA",
		Journal: "Industrial Engineering Journal",
		Year: "2021-22",
		ISSN: "0970-2555",
		JournalLink: "http://www.journal-iiie-india.com/1_jan-dec_22/102_may.pdf",
		ArticleLink: "Volume 51, Issue 5",
		UGCListed: "Yes",
	},
];

export const publicationData2 = [
	{
		titleOfPaper:
			"Factors Affecting Employee Job Satisfaction in Public Sector",
		name: "Prof. Gyana Ranjan Biswal",
		department: "MBA",
		nameofJournal: "Asia Pacific Journal of Research",
		year: "2017",
		issnNo: "2230-5504",
		linkToWebsite: "www.apior.com",
		linkToArtical: "S.N. 45797 of UGC",
		isListed: "Yes",
	},
	{
		titleOfPaper:
			"Implementation of Linear Discriminant Analysis for Odia Numeral Recognition",
		name: "Prof. Sradhanjali Nayak",
		department: "MCA",
		nameofJournal: "International Conference on Information Technology (ICIT)",
		year: "2018",
		issnNo: "978-1-728-0259-7/18",
		linkToWebsite: "ieeexplore.ieee.org",
		linkToArtical: "DOI: 10.1109/icit.2018.00043",
		isListed: "Yes",
	},
	{
		titleOfPaper:
			"International Conference on Image Information Processing ( ICIIP) in IEEE",
		name: "Prof. Sradhanjali Nayak",
		department: "MCA",
		nameofJournal:
			"Classification Performance Analysis of Printed Odia Alphabets using NeturalNetwork based Approach",
		year: "2019",
		linkToWebsite: "https://ieeexplore.ieee.org",
		linkToArtical: "DOI: 10.1109/ICIIP47207.2019.8985822",
		isListed: "Yes",
	},
	{
		titleOfPaper:
			"Recognition of Printed Odia Charactrs and Digits using Optimized Self-Organizing Map Network",
		name: "Prof. Sradhanjali Nayak",
		department: "MCA",
		nameofJournal: "IEEE Xplore",
		year: "2020",
		linkToWebsite: "ieeexplore.ieee.org",
		linkToArtical: "DOI:10.1109/ICCSEA49",
		isListed: "Yes",
	},
	{
		titleOfPaper:
			"International Conference on Image Information Processing ( ICIIP) in IEEE",
		name: "Prof. Sradhanjali Nayak",
		department: "MCA",
		nameofJournal:
			"Shape Feature Based Multi-Class Classification Approach towards Odia Characters employing Extreme Learning Machine",
		year: "2021",
		issnNo: "978-1-6654-3361-7/21",
		linkToWebsite: "www.researchgate.net",
		linkToArtical: "DOI: 10.1109/ICIIP53038.2021.9702563",
		isListed: "Yes",
	},
	{
		titleOfPaper:
			"Impact of Covid-19 on Broader  indices of Indian Stock Market",
		name: "Dr. Ratidev Samal",
		department: "MBA",
		nameofJournal: "International Journal of Monetary Economics & Finance",
		year: "2022",
		issnNo: "1752-0487",
		linkToWebsite: "Mandatory Economics and Finance",
		linkToArtical: "DOI:10.1504/ijmef.2022.10046830",
		isListed: "Yes",
	},
];
