import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import Blockquote from "../Blockquote/Blockquote";
import ReactModal from "react-modal";
import "./FacultyCard.styles.scss";
import { useState } from "react";

const customStyles = {
	content: {
		top: "45%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		padding: 0,
		overflow: "visible",
		transform: "translate(-50%, -50%)",
	},
	overlay: {
		backgroundColor: "#00000073",
	},
};

const FacultyCard = ({
	name,
	designation,
	qualification,
	email,
	experience,
	teachingInterest,
	researchInterest,
	imageUrl,
	publication,
	blockText,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className="p-2 h-100">
			<Row className="bg-white">
				<div
					class="card"
					style={{ cursor: "pointer" }}
					onClick={() => setIsOpen(true)}
				>
					<div class="card__profile" onClick={() => setIsOpen(false)}>
						<img
							src={imageUrl.default ? imageUrl.default : imageUrl}
							alt={name ? name : email}
						/>
						<div class="card__profile__text ml-4">
							<Card.Title as="h5" className="text-brand-1">
								{name ? name : ""}
							</Card.Title>
							<Card.Title as="h6" className="text-brand-1">
								Email : {email ? email : ""}
							</Card.Title>
						</div>
					</div>
				</div>
				<div>
					<ReactModal
						isOpen={isOpen}
						contentLabel="Example Modal"
						ariaHideApp={false}
						onRequestClose={() => setIsOpen(false)}
						style={customStyles}
					>
						       
						<div
							style={{
								backgroundColor: "white",
								width: "1000px",
								height: "430px",
								paddingLeft: "30px",
								paddingRight: "30px",
							}}
						>
							{/* <button onClick={() => setIsOpen(false)}>Open Modal</button>    */}
							<Row>
								<Col md="4">
									<div style={{ height: "400px", overflow: "hidden" }}>
										<img
											src={imageUrl.default ? imageUrl.default : imageUrl}
											alt={name ? name : email}
										/>
									</div>
								</Col>
								{/* name, designation, qualification, email, experience,
								teachingInterest, researchInterest, imageUrl, blockText, */}
								<Col>
									<h1 style={{ fontWeight: 700 }}>{name ? name : ""}</h1>
									<h5>Designation : {designation} </h5>
									<h5>Qualification : {qualification} </h5>
									<h5>Email : {email} </h5>
									{publication != null ? (
										<h5>Publication : {publication} </h5>
									) : (
										null
									)}
									<h5>Teaching Interest: {teachingInterest} </h5>
								</Col>
							</Row>
						</div>
					</ReactModal>
				</div>
			</Row>
		</div>
	);
};

export default FacultyCard;

