import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
import CoursesMiniCard from "../CoursesMiniCard/CoursesMiniCard";
import { coursesData } from "../../static-data/courses.data";

const CoursesResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const rowContainerClasses = classNames({
    "w-75 mx-auto": !(
      currentBreakPoint === "xSmall" || currentBreakPoint === "small"
    ),
  });

  return (
    <PageSection type="lg" bgClass="page-background-1">
      <ResponsivePageHeader title="Explore Our Courses" />
      <div className={rowContainerClasses}>
        <Row className="gx-1">
          {coursesData.map((data, index) => (
            <Col
              md="6"
              className="d-flex align-items-stretch mb-4 mb-md-0"
              key={"course-responsive" + index}
            >
              <CoursesMiniCard {...data} />
            </Col>
          ))}
        </Row>
      </div>
    </PageSection>
  );
};

export default CoursesResponsive;
