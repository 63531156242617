import React from "react";
import { Col, Row } from "react-bootstrap";
// import EnquiryFormResponsive from "../EnquiryFormResponsive/EnquiryFormResponsive";
import PageSection from "../PageSection/PageSection";
// import ContactUsForm from "../ContactUsFrom/ContactUsForm";
import { BsFillHouseFill } from "react-icons/bs";
import { BiWorld } from "react-icons/bi";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
// import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const ContactInformationResponsive = () => {
	return (
		<PageSection className="bg-position--top-center" id="enquiry">
			<ResponsivePageHeader centered={true} title="Contact us" />
			<Row className="justify-content-between">
				<Col md="6" className="mb-5 mb-md-0 ">
					<div className="border mb-4 rounded px-5 py-4 bg-light">
						<p className="display-9 font-weight-normal font-heading-font">
							Campus:
						</p>

						<Row className="mb-2 align-items-start">
							<Col xs="2">
								<CustomIcon
									currentIcon={BsFillHouseFill}
									size="md"
									colorClass="text-brand-2"
								/>
							</Col>
							<Col xs="10">
								<p>Sarada Vihar, Madanpur, BBSR - 54</p>
							</Col>
						</Row>
						<Row className="mb-2 align-items-start">
							<Col xs="2">
								<CustomIcon
									currentIcon={AiFillPhone}
									size="md"
									colorClass="text-brand-2"
								/>
							</Col>
							<Col xs="10">
								<p>(0674) 2113232 / Fax : (0674) 2113242</p>
							</Col>
						</Row>
						<Row className="mb-2 align-items-start">
							<Col xs="2">
								<CustomIcon
									currentIcon={AiFillPhone}
									size="md"
									colorClass="text-brand-2"
								/>
							</Col>
							<Col xs="10">
								<p>9040004375, 9778899991, 9778899990, 8144278720</p>
							</Col>
						</Row>
						<Row className="mb-2 align-items-start">
							<Col xs="2">
								<CustomIcon
									currentIcon={AiTwotoneMail}
									size="md"
									colorClass="text-brand-2"
								/>
							</Col>
							<Col xs="10">
								<a
									href="mailto:contactus@niisgroup.org"
									className="download-link"
								>
									contactus@niisgroup.org
								</a>
							</Col>
						</Row>
					</div>
				</Col>
				<Col md="6">
					<div className="border mb-4 rounded px-5 py-4 bg-white">
						<p className="display-9 font-weight-normal font-heading-font">
							Administrative Office:
						</p>
						<Row className="mb-2 align-items-start">
							<Col xs="2">
								<CustomIcon
									currentIcon={BsFillHouseFill}
									size="md"
									colorClass="text-brand-2"
								/>
							</Col>
							<Col xs="10">
								<p>
									Plot No. - 339 (P), Goutam Nagar, BMC-Mausimaa Main Road, BBSR
									-751014, Odisha,India.
								</p>
							</Col>
						</Row>
						<Row className="mb-2 align-items-start">
							<Col xs="2">
								<CustomIcon
									currentIcon={AiFillPhone}
									size="md"
									colorClass="text-brand-2"
								/>
							</Col>
							<Col xs="10">
								<p>(0674) 2435745 / 2435746 Fax: (0674) 2536559</p>
							</Col>
						</Row>

						<Row className="mb-2 align-items-start">
							<Col xs="2">
								<CustomIcon
									currentIcon={BiWorld}
									size="md"
									colorClass="text-brand-2"
								/>
							</Col>
							<Col xs="10">
								<a
									href="mailto:contactus@niisgroup.org"
									className="download-link"
								>
									contactus@niisgroup.org
								</a>
							</Col>
						</Row>
					</div>
				</Col>
				<Col md="12">
					<div class="map">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14973.781804997008!2d85.7252298!3d20.2403525!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe8052118d487bb86!2sNIIS+Institute+Of+Business+Administration!5e0!3m2!1sen!2sin!4v1527159121709"
							width="100%"
							height="400"
							className="border shadow"
							title="Contact Map"
						></iframe>
					</div>
				</Col>
			</Row>
		</PageSection>
	);
};

export default ContactInformationResponsive;
