import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./coursesMiniCard.style.scss";

const CoursesMiniCard = ({ title, link, description, imageUrl }) => {
  return (
    <Link className="d-block courses-mini-card" to={link}>
      <Image
        src={imageUrl.default ? imageUrl.default : imageUrl}
        alt={title}
        className="img-fluid courses-mini-card--image"
      />
      <h6 className="courses-mini-card--title">{title}</h6>
      <p className="courses-mini-card--description">{description}</p>
    </Link>
  );
};

export default CoursesMiniCard;
