import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import CustomIcon from "../CustomIcon/CustomIcon";

const MCAResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames("text-center mb-4 text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader
				centered={true}
				title="Master in Computer Application (MCA)"
			/>
			<p className={descriptionClasses}>
				The MCA program seeks to implant within the student the most vital
				computer skills required to shape the course of the world with
				innovation. With the emergence of technology and digitalisation, the
				scope of technology has redefined the basis of human livelihood, thus
				making computer skills one of the most vital skill sets of today. We aim
				to prepare the students for the IT industry with Global competence in
				cutting-edge technologies like Cloud Computing, Android Applications,
				JAVA, Oracle, .NET, PHP, CISCO and many others which gradually propel
				them in their career growth. The institute offers a 2 year full time MCA
				programme as per the course curriculum of Biju Patnaik University of
				Technology, Odisha
			</p>
			<p className={descriptionClasses}>
				Eligibility Details : <br /> Passed BCA/ Bachelor Degree in Computer
				Science Engineering or equivalent Degree/+3 Sciences. Selection
				procedure through OJEE as per Govt. Directive.
			</p>
			<a
				href={process.env.PUBLIC_URL + "/NIIS_MCA_Brochure.pdf"}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-link-color font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">Download MCA Brochure</span>
			</a>
		</PageSection>
	);
};

export default MCAResponsive;
