import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row, Image } from "react-bootstrap";

const SportingActivitiesGallaryResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection>
			<ResponsivePageHeader title="Gallery" />
			<Row className="justify-content-center">
				<Col md="3">
					<Image
						src={require("../../assets/sporting-activities/sports (1).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit "
					/>
				</Col>
				<Col md="3">
					<Image
						src={require("../../assets/sporting-activities/sports (2).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit "
					/>
				</Col>
				<Col md="3">
					<Image
						src={require("../../assets/sporting-activities/sports (3).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit "
					/>
				</Col>
				<Col md="3">
					<Image
						src={require("../../assets/sporting-activities/sports (4).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit "
					/>
				</Col>
			</Row>
			<br />
			<Row className="justify-content-center">
				
				<Col md="3">
					<Image
						src={require("../../assets/sporting-activities/sports (5).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit "
					/>
				</Col>
				<Col md="3">
					<Image
						src={require("../../assets/sporting-activities/sports (6).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit "
					/>
				</Col>
				<Col md="3">
					<Image
						src={require("../../assets/sporting-activities/sports (7).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit "
					/>
				</Col>
				<Col md="3">
					<Image
						src={require("../../assets/sporting-activities/sports (8).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit "
					/>
				</Col>
				
			</Row>
			<br/>
			<Row className="justify-content-center">
				
				<Col md="3">
					<Image
						src={require("../../assets/sporting-activities/sports (9).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit "
					/>
				</Col>
				<Col md="3">
					<Image
						src={require("../../assets/sporting-activities/sports (10).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit "
					/>
				</Col>
				
			</Row>
		</PageSection>
	);
};

export default SportingActivitiesGallaryResponsive;
