import React from "react";
import PageSection from "../PageSection/PageSection";
import TrusteeMessageCard from "../TrusteeMessageCard/TrusteeMessageCard";
import {
	chairmanData,
	jmTrusteeData,
	memberData,
} from "../../static-data/chairman.data";
import { Col, Row } from "react-bootstrap";
import MemberCard from "../MemberCard/MemberCard";
const MemberResponsive = () => {
	return (
		<PageSection bgClass="background-color">
			<Row>
				{memberData.map((res) => {
					return (
						<Col style={{ marginBottom: "20px" }} md={6}>
							<MemberCard type="chairman" titlePrefix="From Our" {...res} />
						</Col>
					);
				})}
			</Row>
		</PageSection>
	);
};

export default MemberResponsive;
