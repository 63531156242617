import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
import { studentCellData } from "../../static-data/studentcell.data";
import ClubCard from "../ClubCard/ClubCard";

const StudentCellResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames("text-center mb-4 text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<div>
			<PageSection bgClass="background-color">
				<ResponsivePageHeader centered={true} title="Clubs & Committees" />
				<p className={descriptionClasses}>
				At NIIS, we understand the importance of holistic development and providing students with opportunities 
				to explore their interests and talents beyond academics. To cater to the diverse passions and aspirations 
				of our students, we have a range of vibrant clubs and committees that offer engaging platforms for personal 
				growth, skill development, and community involvement.

				</p>

			</PageSection>
			<PageSection bgClass="background-white">
				<Row>
					{studentCellData.map((data, index) => (
						<Col
							md="4 mt-2"
							className="d-flex align-items-stretch mb-4 mb-md-0"
							key={"about-us-home" + index}
						>
							<ClubCard {...data} />
						</Col>
					))}
				</Row>
			</PageSection>
		</div>
	);
};

export default StudentCellResponsive;
