import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../PageHeader/PageHeader";
import PageSectionHeader from "../PageSectionHeader/PageSectionHeader";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const DreamFestGallary = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection>
			<PageSection>
				<ResponsivePageHeader
					title="COMMERCE CONCLAVE 2021
"
				/>
				<Row className="justify-content-left mb-5">
					<Col md="4">
						<Image
							src={
								require("../../assets/commerceconclave/2021/cc2021 (1).jpg")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="4">
						<Image
							src={
								require("../../assets/commerceconclave/2021/cc2021 (2).jpg")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="4">
						<Image
							src={
								require("../../assets/commerceconclave/2021/cc2021 (3).jpg")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="4">
						<Image
							src={
								require("../../assets/commerceconclave/2021/cc2021 (4).jpg")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="4">
						<Image
							src={
								require("../../assets/commerceconclave/2021/cc2021 (5).jpg")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="4">
						<Image
							src={
								require("../../assets/commerceconclave/2021/cc2021 (6).jpg")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="12 mt-5">
						<a
							href={process.env.PUBLIC_URL + "/Commerce Conclave Brochure 2021.pdf"}
							className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
							target="_blank"
							rel="noopener noreferrer"
						>
							<CustomIcon currentIcon={AiFillFilePdf} size="md" />
							<span className="ml-2">Download Brochure </span>
						</a>
					</Col>
				</Row>

				<ResponsivePageHeader
					title="COMMERCE CONCLAVE 2020
"
					style={{ margin: 10 }}
					className="mt-5"
				/>
				<Row className="justify-content-left  mb-5">
					<Col md="4 ">
						<Image
							src={
								require("../../assets/commerceconclave/2020/2020 (1).JPG")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="4">
						<Image
							src={
								require("../../assets/commerceconclave/2020/2020 (2).JPG")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="4">
						<Image
							src={
								require("../../assets/commerceconclave/2020/2020 (3).JPG")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="12 mt-5">
						<a
							href={process.env.PUBLIC_URL + "/Commerce Conclave Brochure2020.pdf"}
							className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
							target="_blank"
							rel="noopener noreferrer"
						>
							<CustomIcon currentIcon={AiFillFilePdf} size="md" />
							<span className="ml-2">Download Brochure </span>
						</a>
					</Col>
				</Row>
				<ResponsivePageHeader
					title="COMMERCE CONCLAVE 2019
"
					style={{ margin: 10 }}
					className="mt-5"
				/>
				<Row className="justify-content-left  mb-5">
					<Col md="4 ">
						<Image
							src={
								require("../../assets/commerceconclave/2019/2019 (1).JPG")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="4">
						<Image
							src={
								require("../../assets/commerceconclave/2019/2019 (2).JPG")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="4">
						<Image
							src={
								require("../../assets/commerceconclave/2019/2019 (3).JPG")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="12 mt-5">
						<a
							href={process.env.PUBLIC_URL + "/Commerce Conclave Brochure 2019.pdf"}
							className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
							target="_blank"
							rel="noopener noreferrer"
						>
							<CustomIcon currentIcon={AiFillFilePdf} size="md" />
							<span className="ml-2">Download Brochure </span>
						</a>
					</Col>
				</Row>
				<ResponsivePageHeader
					title="COMMERCE CONCLAVE 2017
"
					style={{ margin: 10 }}
					className="mt-5"
				/>
				<Row className="justify-content-left mb-5">
					<Col md="4 ">
						<Image
							src={
								require("../../assets/commerceconclave/2018/2018 (1).JPG")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="4">
						<Image
							src={
								require("../../assets/commerceconclave/2018/2018 (2).JPG")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="4">
						<Image
							src={
								require("../../assets/commerceconclave/2018/2018 (3).JPG")
									.default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="12 mt-5">
						<a
							href={process.env.PUBLIC_URL + "/commerce conclave 2017.pdf"}
							className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
							target="_blank"
							rel="noopener noreferrer"
						>
							<CustomIcon currentIcon={AiFillFilePdf} size="md" />
							<span className="ml-2">Download Brochure </span>
						</a>
					</Col>
				</Row>
			</PageSection>
		</PageSection>
	);
};

export default DreamFestGallary;
