import React from "react";
import { Image } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { publicationData } from "../../static-data/publication.data";
import { publicationData2 } from "../../static-data/publication.data";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { bookPublicationData } from "../../static-data/bookpublication.data";
const BookPublicationResponsive = () => {
	return (
		<>
			<PageSection bgClass="bg-10">
				<ResponsivePageHeader centered={true} title="Book  Publications" />

				<Table striped bordered>
					<thead>
						<tr>
							<th>Name Of The Teacher</th>
							<th>Title of the book/chapters  published</th>
							<th>National/ International</th>
							<th>Year of publication</th>
							<th>ISBN number of the proceeding</th>
							<th>Name of the publisher</th>
						</tr>
					</thead>
					<tbody>
						{bookPublicationData.map((val) => {
							console.log(val.length);
							return (
								<tr>
									<td>{val.TeacherName}</td>
									<td>{val.BookTitle}</td>
									<td>{val.National}</td>
									<td>{val.YearOfPublication}</td>
									<td>{val.ISBNNumber}</td>
									<td>{val.PublisherName}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</PageSection>
			
			
		</>
	);
};

export default BookPublicationResponsive;
