import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import CourseInfoResponsive from "../../components/CourseInfoResponsive/CourseInfoResponsive";
import FacultyResponsive from "../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../components/CoursesResponsive/CoursesResponsive";

const CoursesPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-7">
				<HeroCaption
					title="Courses"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<CourseInfoResponsive />
			{/* <FacultyResponsive /> */}
			<CoursesResponsive />
		</PageWrapper>
	);
};

export default CoursesPage;
