import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row} from "react-bootstrap";

function JIPSITInfoResponsive() {
    const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="JIPSIT" />
			<p className={descriptionClasses}>
            The Journal of Innovative Professional Science & IT (JIPSIT) is a prestigious bi-annual research 
            journal published by the NIIS Institute of Business Administration, Bhubaneswar. At JIPSIT, 
            we are committed to advancing knowledge and fostering innovation in the fields of science and information technology.

            <br/>
            <br/>

            Our mission is to provide a platform for researchers, academicians, and industry professionals 
            to share groundbreaking research, insights, and advancements in diverse domains within the realms of science and IT.


			</p>

			<br/>
			<br/>

			<Row className="justify-content-center">
				<Col md="3">
					<a
						href={process.env.PUBLIC_URL + "/JIPSIT JULY 2019.pdf"}
						className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
						target="_blank"
						rel="noopener noreferrer"
					>
						<CustomIcon currentIcon={AiFillFilePdf} size="md" />
						<span className="ml-2">JIPSIT JULY 2019</span>
					</a>
				</Col>
			
			
				<Col md="3">
					<a
						href={process.env.PUBLIC_URL + "/JIPSIT JAN 2020.pdf"}
						className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
						target="_blank"
						rel="noopener noreferrer"
					>
						<CustomIcon currentIcon={AiFillFilePdf} size="md" />
						<span className="ml-2">JIPSIT JAN 2020</span>
					</a>
				</Col>
			
				<Col md="3">
					<a
						href={process.env.PUBLIC_URL + "/JIPSIT JULY 2020.pdf"}
						className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
						target="_blank"
						rel="noopener noreferrer"
					>
						<CustomIcon currentIcon={AiFillFilePdf} size="md" />
						<span className="ml-2">JIPSIT JULY 2020</span>
					</a>
				</Col>
				<Col md="3">
					<a
						href={process.env.PUBLIC_URL + "/JIPSIT JAN 2021.pdf"}
						className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
						target="_blank"
						rel="noopener noreferrer"
					>
						<CustomIcon currentIcon={AiFillFilePdf} size="md" />
						<span className="ml-2">JIPSIT JAN 2021</span>
					</a>
				</Col>

			</Row>

			<br/>
			<br/>
			
			<Row className="justify-content-center">
				<Col md="3">
					<a
						href={process.env.PUBLIC_URL + "/JIPSIT JULY 2021.pdf"}
						className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
						target="_blank"
						rel="noopener noreferrer"
					>
						<CustomIcon currentIcon={AiFillFilePdf} size="md" />
						<span className="ml-2">JIPSIT JULY 2021</span>
					</a>
				</Col>

				<Col md="3">
					<a
						href={process.env.PUBLIC_URL + "/JIPSIT JAN 2022.pdf"}
						className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
						target="_blank"
						rel="noopener noreferrer"
					>
						<CustomIcon currentIcon={AiFillFilePdf} size="md" />
						<span className="ml-2">JIPSIT JAN 2022</span>
					</a>
				</Col>

				<Col md="3">
					<a
						href={process.env.PUBLIC_URL + "/JIPSIT JULY 2022.pdf"}
						className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
						target="_blank"
						rel="noopener noreferrer"
					>
						<CustomIcon currentIcon={AiFillFilePdf} size="md" />
						<span className="ml-2">JIPSIT JULY 2022</span>
					</a>
				</Col>
			
				<Col md="3">
					<a
						href={process.env.PUBLIC_URL + "/JIPSIT JAN 2023.pdf"}
						className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
						target="_blank"
						rel="noopener noreferrer"
					>
						<CustomIcon currentIcon={AiFillFilePdf} size="md" />
						<span className="ml-2">JIPSIT JAN 2023</span>
					</a>
				</Col>
			</Row>

		</PageSection>
	);
}

export default JIPSITInfoResponsive