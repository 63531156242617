export const navbarData = [
	{
		title: "Campus",
		link: "/campus",
	},
	{
		title: "Campus Activities",
		link: `/campus-activities`,
		subLinks: [
			{ title: "Dream Fest", link: "/dream-fest" },
			{
				title: "Faculty Development Program",
				link: "/faculty-development-program",
			},
			{ title: "Seminars", link: "/seminars" },
			{ title: "CSR & Sustainability", link: "/csr-&-sustainability" },
			{ title: "Commerce Conclave", link: "/commerce-conclave" },
			{title: "Sporting Activities", link:"/sporting-activities"},
		],
	},
	{
		title: "Student's Corner",
		link: `/student-corner`,
		subLinks: [
			{ title: "Guidelines", link: "/guidelines" },
			{ title: "Anti-Ragging Cell", link: "/anti-ragging-policy" },
			{ title: "Clubs & Committees", link: "/clubs-committees" },
			{title: "Sexual Harassment Cell", link: "/sexual-harassment-cell"},
			{title: "Grievance Cell", link:"/grievance-cell"},
		],
	},
	{
		title: "NAAC",
		link: `/naac`,
		subLinks: [
			// { title: "IQAC", link: "/iqac" },
			{ title: "Statutory Declarations", link: "/statutory-declarations" },
			// { title: "SSR", link: "/ssr" },
		],
	},
	{
		title: "Enquire Now",
		link: `/#enquiry`,
		internal: true,
	},
	{
		title: "Home",
		leftMargin: true,
		mobileStart: true,
		link: "/",
	},
	{
		title: "About Us",
		link: "/about-us",
		subLinks: [
			{ title: "About NIIS", link: "/about-niis" },
			// { title: "Management & Leadership ", link: "/about-us" },
			{ title: "Alumni", link: "/alumni" },
			{
				title: "Leadership & Management ",
				link: "/leadership-&-management",
			},
		],
	},

	{
		title: "Academics",
		link: "/academics",
		subLinks: [
			{ title: "Courses", link: "/courses" },
			{ title: "Faculty", link: "/faculty" },
			{ title: "Research", link: "/research" },
			{ title: "Library & Resources ", link: "/library-resources" },
			{ title:"JIPSIT", link: "/jipsit"},
			// {
			//   title: "Events",
			//   link: "/",
			// },
		],
	},
	{
		title: "Industry Interface Centre",
		link: "/industry-interface-centre",
	},
	{
		title: "Contact Us",
		link: "/contact-us",
	},
];

export const newLinks = [
	"/",
	"/about-us",
	"/about/academic-advisor",
	"/academics/courses",
	"/academics/courses/mba",
	"/industry-interface-centre",
];
