import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import LibraryInfoResponsive from "../../components/LibraryInfoResponsive/LibraryInfoResponsive";
import PageSection from "../../components/PageSection/PageSection";
import ResponsivePageHeader from "../../components/ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import FacultyResponsive from "../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../components/CoursesResponsive/CoursesResponsive";
import JornalPublicationResponsive from "../../components/JornalPublicationResponsive/JornalPublicationResponsive";

const LibraryandResourcesPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-7">
				<HeroCaption
					title="Library & Resources"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<LibraryInfoResponsive />

			<PageSection type="lg" bgClass="page-background-1">
			<ResponsivePageHeader title="NPTEL Notice Board" />
			<Row className="justify-content-center">

				<Col md="4">
					<a
						href={process.env.PUBLIC_URL + "/Acknowledgement.pdf"}
						className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
						target="_blank"
						rel="noopener noreferrer"
					>
						<CustomIcon currentIcon={AiFillFilePdf} size="md" />
						<span className="ml-2">Acknowledgement</span>
					</a>
				</Col>
			
			
				<Col md="4">
					<a
						href={process.env.PUBLIC_URL + "/Stars NPTEL Believer-1.pdf"}
						className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
						target="_blank"
						rel="noopener noreferrer"
					>
						<CustomIcon currentIcon={AiFillFilePdf} size="md" />
						<span className="ml-2">Stars NPTEL Believer-1</span>
					</a>
				</Col>
			
				<Col md="4">
					<a
						href={process.env.PUBLIC_URL + "/Stars NPTEL Believer-2.pdf"}
						className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
						target="_blank"
						rel="noopener noreferrer"
					>
						<CustomIcon currentIcon={AiFillFilePdf} size="md" />
						<span className="ml-2">Stars NPTEL Believer-2</span>
					</a>
				</Col>
			</Row>

			<br/>
			<br/>

			<Row className="justify-content-center">

				<Col md="4">
					<a
						href={process.env.PUBLIC_URL + "/Statistics Course Wise NPTEL.pdf"}
						className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
						target="_blank"
						rel="noopener noreferrer"
					>
						<CustomIcon currentIcon={AiFillFilePdf} size="md" />
						<span className="ml-2">Statistics Course Wise NPTEL</span>
					</a>
				</Col>
			
			
				<Col md="4">
					<a
						href={process.env.PUBLIC_URL + "/Topper List.pdf"}
						className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
						target="_blank"
						rel="noopener noreferrer"
					>
						<CustomIcon currentIcon={AiFillFilePdf} size="md" />
						<span className="ml-2">Topper List</span>
					</a>
				</Col>
			
			</Row>


			</PageSection>
		</PageWrapper>
	);
};

export default LibraryandResourcesPage;
