import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import CustomIcon from "../CustomIcon/CustomIcon";
import { Col, Row } from "react-bootstrap";
import { sem1 } from "../../static-data/lessonplanmca.data";
import { sem2 } from "../../static-data/lessonplanmca.data";
import { sem3 } from "../../static-data/lessonplanmca.data";

const ResourcesResponsive = ({ headerContent, lessonPlan, copo }) => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames("text-center mb-4 text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="Resources" />
			<p className={descriptionClasses}>{headerContent}</p>
			<p className="display-9 mb-1 font-weight-normal font-heading-font">
				Lesson Plan
			</p>
			<Row className="justify-content-between">
				<Col md="6" className="mb-2 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 pt-1 pb-4 bg-light"
						style={{ height: "90%" }}
					>
						<p className="display-10 mb-0 mt-3 font-weight-normal font-heading-font">
							Semester 1st
						</p>
						<Row>
							{sem1.map((res) => {
								return (
									<Col md="4" key={res.id}>
										<a
											href={process.env.PUBLIC_URL + res.link}
											className="d-flex  text-uppercase text-left text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
											target="_blank"
											rel="noopener noreferrer"
										>
											<CustomIcon currentIcon={AiFillFilePdf} size="md" />
											<span className="ml-2">{res.title}</span>
										</a>
									</Col>
								);
							})}
						</Row>
					</div>
				</Col>

				<Col md="6" className="mb-2 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 pt-1 pb-4 bg-light"
						style={{ height: "90%" }}
					>
						<p className="display-10 mb-0 mt-3 font-weight-normal font-heading-font">
							Semester 2nd
						</p>
						<Row>
							{sem2.map((res) => {
								return (
									<Col md="4" key={res.id}>
										<a
											href={process.env.PUBLIC_URL + res.link}      
											className="d-flex  text-uppercase text-left text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
											target="_blank"
											rel="noopener noreferrer"
										>
											<CustomIcon currentIcon={AiFillFilePdf} size="md" />
											<span className="ml-2">{res.title}</span>
										</a>
									</Col>
								);
							})}
						</Row>
					</div>
				</Col>

				<Col md="6" className="mb-2 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 pt-1 pb-4 bg-light"
						style={{ height: "90%" }}
					>
						<p className="display-10 mb-0 mt-3 font-weight-normal font-heading-font">
							Semester 3rd
						</p>
						<Row>
							{sem3.map((res) => {
								return (
									<Col md="4" key={res.id}>
										<a
											href={process.env.PUBLIC_URL + res.link}
											className="d-flex  text-uppercase text-left text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
											target="_blank"
											rel="noopener noreferrer"
										>
											<CustomIcon currentIcon={AiFillFilePdf} size="md" />
											<span className="ml-2">{res.title}</span>
										</a>
									</Col>
								);
							})}
						</Row>
					</div>
				</Col>
				
			</Row>

			<p className="display-9 mb-1 font-weight-normal font-heading-font">
				Course Outcomes & Program Outcomes
			</p>
			<Row className="justify-content-between">
				<Col md="6" className="mb-5 mb-md-0 ">
					<div className="border mb-4 rounded px-5 py-4 bg-light">
						<a
							href={process.env.PUBLIC_URL + "/PO MBA.pdf"}
							className="d-flex  text-uppercase text-center text-link-color font-weight-medium font-sm font-paragraph-font"
							target="_blank"
							rel="noopener noreferrer"
						>
							<CustomIcon currentIcon={AiFillFilePdf} size="md" />
							<span className="ml-2">Download Programme Outcome</span>
						</a>
						<a
							href={process.env.PUBLIC_URL + "/CO MBA.pdf"}
							className="d-flex  text-uppercase text-center text-link-color font-weight-medium font-sm font-paragraph-font mt-3"
							target="_blank"
							rel="noopener noreferrer"
						>
							<CustomIcon currentIcon={AiFillFilePdf} size="md" />
							<span className="ml-2">Download Course Outcome</span>
						</a>
					</div>
				</Col>
			</Row>
		</PageSection>
	);
};

export default ResourcesResponsive;
