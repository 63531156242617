import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const CourseInfoResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="Library & Resources" />
			<p className={descriptionClasses}>
				The library at NIIS Institute of Business Administration is a hub of
				knowledge and information, providing students with access to a wide
				range of resources to support their learning and research. The library
				is equipped with the latest technology and resources to help students
				stay up-to-date with the latest information and trends in their field of
				study. <br />
				One of the key resources at the library is the classification of books
				using the DDC (22nd Edition) classification system, which is widely used
				by libraries all over the world. This system provides a systematic and
				organized approach to classifying books, making it easy for students to
				find the information they need. <br />
				In addition to the classification system, the library also has access to
				DELNET, which is a national level consortium of libraries in India. This
				resource provides students with access to a large number of books,
				journals, and other information resources, making it an ideal place for
				students to conduct research and access information. <br />
				The library also provides students with a range of study materials,
				including printed journals and textbooks. These materials are carefully
				selected and updated to reflect the latest developments in the field of
				business and management, ensuring that students have access to the most
				up-to-date information and knowledge. <br />
				In conclusion, the library at NIIS Institute of Business Administration
				is a valuable resource for students, providing them with access to the
				latest information, knowledge, and resources to support their learning
				and research. Whether you are a student or a researcher, the library
				provides you with the resources you need to succeed.
			</p>
			<a
				href={process.env.PUBLIC_URL + "/NPTELMOOCPOOLSYLLABUS.pdf"}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">DOWNLOAD NPTELMOOCPOOL SYLLABUS</span>
			</a>
		</PageSection>
	);
};

export default CourseInfoResponsive;
