export const skillDevelopmentData = [
	{
		imageUrl: require("../assets/skill-development-center/1.png"),
		title: "Career in Finance",
		description: (
			<>
				Financial management is an essential component of every company.It is
				the practise of preparing, coordinating, managing, and reporting
				financial capital in order to meet the aims and objectives of an
				organisation.
				<br />
				Students studying Finance as a specialisation receive comprehensive
				financial preparation, which includes gathering specific financial data
				about the business(es) and representing the data so that critical
				estimates about the firm, such as valuation, can be generated.
			</>
		),
	},
	{
		imageUrl: require("../assets/skill-development-center/2.png"),
		title: "Career In Marketing",
		description:
			"Students in the Marketing Specialization are taught about marketing roles, problems, sales forecasting, and other topics.The discovery and comprehension of consumer desires is the focus of marketing and sales. The processes and practises involved in marketing and distributing products or services are referred to as sales, on the other hand, marketing refers to the method of advertising, delivering, and supplying a good or service.",
	},
	{
		imageUrl: require("../assets/skill-development-center/3.png"),
		title: "Careers in Human Resources",
		description:
			"Students receive instruction in the general HR management role, which includes various day-to-day HR operations management. Human Resource Information Systems (HRIS), Employee Relations, Training and Development, Benefits, Compensation, and Organizational Development are all topics that need to be addressed.",
	},
	{
		imageUrl: require("../assets/skill-development-center/4.png"),
		title: "Careers in Digital Marketing Profiles",
		description:
			"Organizations all over the globe are seeing the value of going new, and marketing is one of the first and most essential roles to evolve.Digital Marketing students are taught the cutting-edge skills that an organisation needs to establish its identity to attract the widest possible audience.",
	},
	{
		imageUrl: require("../assets/skill-development-center/5.png"),
		title: "areers in Project Management Profiles",
		description:
			"This program teaches students the essentials of managing projects, including project planning, execution, monitoring and control, and closure. The program prepares students for project management roles in various industries.",
	},
	{
		imageUrl: require("../assets/skill-development-center/5.png"),
		title: "Careers in Consultancy profiles",
		description:
			"This program trains students in the techniques and approaches used by consultants to solve complex business problems. The curriculum covers areas such as strategy, process improvement, change management, and risk assessment.",
	},
	{
		imageUrl: require("../assets/skill-development-center/5.png"),
		title: "Careers in Cloud Computing",
		description:
			" This program provides students with in-depth knowledge of cloud technology, network security and cloud infrastructure.",
	},
	{
		imageUrl: require("../assets/skill-development-center/5.png"),
		title: "Careers in Business Analytics",
		description:
			"BA students are taught how to use Artificial Intelligence (AI), Data Quality, Data Analytics, Statistical Methods, and Data Visualization Models to assess past results and direct an organization's business planning.",
	},
	{
		imageUrl: require("../assets/skill-development-center/5.png"),
		title: "Careers in Software Development",
		description:
			"his program that trains students in software design, software development methodologies and programming languages",
	},
	{
		imageUrl: require("../assets/skill-development-center/5.png"),
		title: "Careers in Data Science and Machine Learning",
		description:
			"This program focuses on teaching students the theories, algorithms and tools required for big data analysis, machine learning and artificial intelligence. ",
	},
	{
		imageUrl: require("../assets/skill-development-center/5.png"),
		title: "Careers in Cybersecurity",
		description:
			"This program that trains students to secure information and communication systems, to detect and respond to cyber threats and to develop secure software.   ",
	},
];
