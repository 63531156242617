import React from "react";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../../components/CustomIcon/CustomIcon";

const ResearchArchives = () => {
	return (
		<>
			<PageSection bgClass="bg-10">
				<ResponsivePageHeader centered={true} title="Archives" />
                <a
				href={process.env.PUBLIC_URL + "/Final Conferences Brochure 29 July 2023 (5 pages) (30.06.2023).pdf"}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">Final Conferences Brochure 29 July 2023</span>
			</a>
            <br/>
            <a
				href={process.env.PUBLIC_URL + "/CERTIFICATE 380097-001 (3).pdf"}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">CERTIFICATE 380097-001</span>
			</a>
				
			</PageSection>
			
			
		</>
	);
};

export default ResearchArchives;
