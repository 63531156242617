export const infographyData = [
  {
    value: "2500+",
    caption: (
      <>
        Passed Out <br /> Post Graduates
      </>
    ),
  },
  {
    value: "100+",
    caption: (
      <>
        qualified <br /> staff
      </>
    ),
  },
  {
    value: "2500+",
    caption: (
      <>
        Placed <br />
        Studnents
      </>
    ),
  },
  {
    value: "300+",
    caption: (
      <>
        STUDENTS <br /> EVERY YEAR
      </>
    ),
  },
  {
    value: "500+",
    caption: (
      <>
        graduates working in <br /> Fortune Global 500 Companies
      </>
    ),
  },
];

export const industryInterfaceInfographyData = [
  {
    value: "2500+",
    caption: (
      <>
        Placed <br />
        Studnents
      </>
    ),
  },
  {
    value: "300+",
    caption: (
      <>
        STUDENTS <br /> EVERY YEAR
      </>
    ),
  },
  {
    value: "100+",
    caption: (
      <>
        Companies recruit from <br /> us every year
      </>
    ),
  },
  {
    value: "500+",
    caption: (
      <>
        graduates working in <br /> Fortune Global 500 Companies
      </>
    ),
  },
  {
    value: "6,00,000+",
    caption: (
      <>
        Median payscale offered <br /> by our recruiting companies
      </>
    ),
  },
];
