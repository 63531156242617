import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";


const GrievanceCellResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames("text-center mb-4 text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<div>
			<PageSection bgClass="background-color">
				<ResponsivePageHeader centered={true} title="Grievance Cell" />
				<p style={{ textAlign: "left" }}>
                At NIIS, we are dedicated to providing a supportive and inclusive environment for all our students, faculty, and staff. 
                We understand that concerns or grievances may arise from time to time, and we are committed to addressing them promptly and fairly. 
                To ensure a systematic and impartial resolution of such issues, we have established a Grievance Cell at NIIS.

                <br />
                <br />

                The Grievance Cell serves as a confidential and neutral platform for individuals to voice their concerns and seek redressal. 
                It is comprised of experienced professionals who are trained in handling grievances with sensitivity and discretion. 
                Our primary objective is to provide a safe space for individuals to share their grievances and to work towards a satisfactory resolution.

                <br />
                <br />
                
                At NIIS, we believe in maintaining transparency and fairness in our processes. The Grievance Cell follows a structured approach, 
                where complaints are thoroughly examined, and investigations are conducted in a timely manner. We ensure that all parties 
                involved are given an opportunity to present their perspectives, and the findings are communicated transparently.

				</p>

				{/* <a
					href={
						"https://forms.gle/arH7D9YsGNydgbVf8"
					}
					className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
					target="_blank"
					rel="noopener noreferrer"
				>
					<CustomIcon currentIcon={AiFillFilePdf} size="md" />
					<span className="ml-2">
					Grievance Cell Form
					</span>
				</a> */}
				
			</PageSection>
			
		</div>
	);
};

export default GrievanceCellResponsive;
