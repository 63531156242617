import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import AboutNibaResponsive from "../../components/AboutNibaResponsive/AboutNibaResponsive";
import FromStudentsResponsive from "../../components/FromStudentsResponsive/FromStudentsResponsive";
import InfographyResponsive from "../../components/InfographyResponsive/InfographyResponsive";
import TrusteeResponsive from "../../components/TrusteeResponsive/TrusteeResponsive";
import QualityPolicyContentResponsive from "../../components/QualityPolicyContentResponsive/QualityPolicyContentResponsive";

const AboutUsPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-4">
				<HeroCaption
					title="About Us"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<AboutNibaResponsive />
			<TrusteeResponsive />
			<InfographyResponsive />
			<FromStudentsResponsive />
			<QualityPolicyContentResponsive />
		</PageWrapper>
	);
};

export default AboutUsPage;
