import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import JIPSITInfoResponsive from "../../components/JIPSITInfoResponsive/JIPSITInfoResponsive";

const JIPSIT = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-7">
				<HeroCaption
					title="JIPSIT"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<JIPSITInfoResponsive/>

		</PageWrapper>
	);
};

export default JIPSIT;
