import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import CustomIcon from "../CustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const CampusCommunityServiceResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="CSR & Sustainability" />
			<p className={descriptionClasses}>
				The NIIS Institute of Business Administration is committed to promoting
				corporate social responsibility (CSR) and sustainability, and has been
				actively engaged in various initiatives to support this cause. The
				Institute recognizes the importance of creating a sustainable future,
				not only for its students, but also for the wider community. <br />
				The National Service Scheme (NSS), a service programme offered by NIIS
				that is focused on the needs of its students, is an addition to
				education. This programme is an admirable example of academic extension
				that inculcates a spirit of volunteerism in both students and teachers
				through engagement with the local community. This project is a
				government-sponsored initiative run by the Department of Youth Affairs
				and Sports in India. The plan was formally introduced on September 24,
				1969. More than 3 Crore kids have benefited from this extensive student
				volunteer programme so far during its 45 years of fruitful existence.
				<br />
				The NIIS Institute of Business Administration is committed to promoting
				sustainability and CSR, and is always exploring new ways to create a
				positive impact on the community. The Institute is proud of its efforts
				to date and is looking forward to continuing its work in this important
				area in the years to come
			</p>
			<a
				href={
					process.env.PUBLIC_URL + "/InitiativesofNIBAtowardsESGreenAudit.pdf"
				}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">
					DOWNLOAD Initiatives of NIBA towards ES- Green Audit
				</span>
			</a>
		</PageSection>
	);
};

export default CampusCommunityServiceResponsive;
